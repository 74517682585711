<template>
  <div id="centerLeft1">
    <div class="title">
      <span class="text">信息服务</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-num">48%</div>
        <div class="left-text">匹配成功率</div>
        <img src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-match-icon1.png" alt="" class="left-img">
      </div>
      <div class="right">
        <div class="item" v-for="(item, index) in data" :key="index">
          <div class="item-title">{{ item.title }}</div>
          <div class="item-content" v-if="index == 0">
            <div class="rectangle1"></div>
            <div class="rectangle2"></div>
            <div class="round1"></div>
            <div class="round2"></div>
          </div>
          <div class="item-content" v-if="index == 1">
            <div class="rectangle1"></div>
            <div class="rectangle3"></div>
            <div class="round3"></div>
            <div class="round4"></div>
          </div>
          <div class="item-content" v-if="index == 2">
            <div class="rectangle1"></div>
            <div class="rectangle4"></div>
            <div class="round5"></div>
            <div class="round6"></div>
          </div>
          <div class="item-content" v-if="index == 3">
            <div class="rectangle1"></div>
            <div class="rectangle5"></div>
            <div class="round7"></div>
            <div class="round8"></div>
          </div>
          <div class="item-text">
            <span class="peopleNumber">{{ item.number }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          title: '新增供求信息',
          number: 1060,
          unit: '条'
        },
        {
          title: '供求匹配数量',
          number: 396,
          unit: '条'
        },
        {
          title: '新增农服信息',
          number: 1203,
          unit: '条'
        },
        {
          title: '农服成交数量',
          number: 1840,
          unit: '条'
        }
      ]
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
#centerLeft1 {
  width: 400px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url('../../assets/title.png');
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    margin-top: 18px;

    .left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

      .left-num {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        color: rgba(79, 240, 255, 1);
        // margin-top: 10px;
        margin-bottom: 6px;
      }

      .left-text {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.86px;
        color: rgba(255, 255, 255, 1);
      }

      .left-img {
        width: 125px;
        height: 151px;
      }
    }

    .right {
      padding-top: 20px;

      .item {
        width: 260px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .item-title {
          font-size: 12px;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
          margin-right: 10px;
        }

        .item-content {
          position: relative;
          margin-right: 10px;

          .rectangle1 {
            width: 103px;
            height: 4px;
            opacity: 1;
            background: rgba(135, 158, 169, 0.15);
          }

          .rectangle2 {
            width: 54px;
            height: 4px;
            opacity: 1;
            background: linear-gradient(270deg, rgba(51, 204, 204, 1) 0%, rgba(31, 89, 89, 0.25) 100%);
            position: absolute;
            top: 0;
            left: 0;
          }

          .round1 {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            border: 1px solid rgba(82, 204, 204, 0.4);
            position: absolute;
            top: -200%;
            left: 44px;
          }

          .round2 {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(80, 230, 230, 1);
            box-shadow: 0px 0px 10px 3px rgba(25, 96, 102, 1), 0px 0px 4px 1px rgba(32, 120, 128, 1);
            position: absolute;
            top: 0;
            left: 52px;
          }

          .rectangle3 {
            width: 19px;
            height: 4px;
            opacity: 1;
            background: linear-gradient(180deg, rgba(122, 163, 204, 1) 0%, rgba(96, 112, 128, 0.1) 100%);
            position: absolute;
            top: 0;
            left: 0;
          }

          .round3 {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            border: 1px solid rgba(82, 204, 204, 0.4);
            position: absolute;
            top: -200%;
            left: 12px;
          }

          .round4 {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(179, 217, 255, 1);
            box-shadow: 0px 0px 10px 3px rgba(77, 103, 129, 1), 0px 0px 4px 1px rgba(95, 124, 153, 1);
            position: absolute;
            top: 0;
            left: 19px;
          }
          .rectangle4 {
            width: 66px;
            height: 4px;
            opacity: 1;
            background: linear-gradient(270deg, rgba(51, 204, 204, 1) 0%, rgba(31, 89, 89, 0.25) 100%);
            position: absolute;
            top: 0;
            left: 0;
          }

          .round5 {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            border: 1px solid rgba(82, 204, 204, 0.4);
            position: absolute;
            top: -200%;
            left: 54px;
          }

          .round6 {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(179, 217, 255, 1);
            box-shadow: 0px 0px 10px 3px rgba(25, 96, 102, 1), 0px 0px 4px 1px rgba(32, 120, 128, 1);
            position: absolute;
            top: 0;
            left: 62px;
          }
          .rectangle5 {
            width: 83px;
            height: 4px;
            opacity: 1;
            background: linear-gradient(180deg, rgba(122, 163, 204, 1) 0%, rgba(96, 112, 128, 0.1) 100%);
            position: absolute;
            top: 0;
            left: 0;
          }

          .round7 {
            width: 21px;
            height: 21px;
            border-radius: 50%;
            border: 1px solid rgba(82, 204, 204, 0.4);
            position: absolute;
            top: -200%;
            left: 75px;
          }

          .round8 {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: rgba(179, 217, 255, 1);
            box-shadow: 0px 0px 10px 3px rgba(77, 103, 129, 1), 0px 0px 4px 1px rgba(95, 124, 153, 1);
            position: absolute;
            top: 0;
            left: 83px;
          }
        }
        .peopleNumber {
          font-size: 16px;
          font-weight: 900;
          letter-spacing: 1px;
          color: rgba(208, 222, 238, 1);
        }

        .unit {
          font-size: 12px;
          font-weight: 500;
          color: rgba(143, 171, 191, 1);
          opacity: 0.6;
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
