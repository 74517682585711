<template>
  <div id="centerRight1">
    <div class="title">
      <span class="text">农服数据</span>
    </div>
    <div class="centerRight1Box">
      <div class="content">
        <div class="rate">
          <div class="rate-text">本次评分：</div>
          <el-rate v-model="value2" disabled :colors="colors"> </el-rate>
        </div>
        <div class="item" v-for="(item, index) in data" :key="index">
          <div class="item-img">
            <img :src="item.url" alt="" class="item-icon" />
          </div>
          <div class="text-content">
            <div class="item-text">{{ item.text }}</div>
            <div class="number-content">
              <span class="item-num" v-change="{ eUnit: '' }">{{ item.number}}</span>
              <span class="item-unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="timeline">
        <div class="time-line-item" v-for="(item, j) in activities" :key="j">
          <img
            src="https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right3-icon4.png"
            alt=""
            class="timeline-img"
          />
          <div class="timeline-item-content">
            <div class="time">{{ item.time }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          url: "https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right3-icon2.png",
          text: "总收益",
          number: "1926",
          unit: "万元",
        },
        {
          url: "https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right3-icon3.png",
          text: "服务次数",
          number: "126",
          unit: "次",
        },
      ],
      value2: 4,
      colors: [
        "rgba(79, 240, 255, 0.1)",
        "rgba(79, 240, 255, 1)",
        "rgba(79, 240, 255, 1)",
      ],
      activities: [
        {
          time: "2023-08-08",
          text: "接到甘肃省景泰县枸杞采购订单",
        },
        {
          time: "2023-08-09",
          text: "安排 158人到基地执行订单",
        },
        {
          time: "2023-08-09",
          text: "开始农服工作",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 676px;

  .title {
    width: 100%;
    height: 32px;
    background-image: url("../../assets/title2.png");
    background-size: cover;
    display: flex;
    align-items: center;

    .text {
      margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
    }
  }
  .centerRight1Box {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding-left: 9px;
    margin-right: 44px;

    .item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .item-img {
        width: 64px;
        height: 64px;
        background-image: url("https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right3-icon1.png");
        background-size: cover;
        position: relative;
        margin-right: 14px;

        .item-icon {
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-36%, -61%);
        }
      }

      .item-text {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.25px;
        margin-bottom: 3px;
      }

      .item-num {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1.88px;
      }

      .item-unit {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.25px;
        color: rgba(255, 255, 255, 1);
        opacity: 0.5;
      }
    }
  }

  .rate {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .rate-text {
      font-size: 14px;
      font-weight: 700;
      margin-left: 28px;
      margin-left: 0px !important;
    }
  }

  .timeline{
    flex: 1;
  }

  .time-line-item {
    width: 100%;
    // height: 100px;
    display: flex;
    border-left: 1px solid rgba(64, 163, 255, 1);
    display: flex;
    justify-content: center;
    position: relative;

    .timeline-img {
      width: 18px;
      height: 35px;
      position: absolute;
      top: 10px;
      left: -5px;
    }

    .timeline-item-content {
      width: 372px;

      .time {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        color: rgba(79, 240, 255, 1);
        margin-top: 17px;
      }

      .text {
        width: 372px;
        height: 30px;
        opacity: 1;
        background: rgba(255, 255, 255, 0.1);
        line-height: 30px;
        padding-left: 13px;
        font-size: 14px;
        font-weight: 700;
        margin-top: 5px;
      }
    }
  }
}
</style>
