<template>
  <div id="centerRight1">
    <div class="title">
      <span class="text">热门农技问答</span>
    </div>
    <div class="title-border">
      <div class="border1"></div>
      <div class="border2"></div>
      <div class="border3"></div>
      <div class="border4"></div>
    </div>
    <!-- <CenterRight1Chart/> -->
    <div class="item" v-for="(item, index) in data" :key="index">
      <div class="item-title">{{ item.titile }}</div>
      <div class="item-content">
        <img :src="item.url" alt="" class="item-img">
        <div class="item-text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [
        {
          titile: '如何选择优质的种子？',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page-right-center-right-icon1.png',
          text: '选择优质的种子需要考虑种子的品种、来源、品质和适应性等多个因素。一般来说，要选择适应当地气候和土壤条件的品种，同时注意种子的新鲜程度、外观质量和生长力等指标。'
        },
        {
          titile: '如何预防农作物病虫害？',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page-right-center-right-icon2.png',
          text: '预防农作物病虫害需要采取综合治理措施，包括选用抗病虫害的品种、加强田间管理、合理使用农药和化肥等。同时也要注意及时发现和处理病虫害，防止病虫害扩散。'
        },
        {
          titile: '如何进行农产品质量检测？',
          url: 'https://nonglian.oss-cn-shanghai.aliyuncs.com/page-right-center-right-icon3.png',
          text: '农产品质量检测需要根据不同产品的特点，选用不同的检测方法和标准。一般来说，检测方法包括化学分析、生物学检验、物理检测等。同时需要依据国家和地方的相关法规和标准进行检测。'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
  width: 400px;
  // margin-top: 10px;

  .title {
    width: 100%;
    height: 29px;
    // background-image: url("../../assets/title.png");
    // background-size: cover;
    display: flex;
    align-items: center;
    padding-left: 16px;

    &::after {
      background: linear-gradient(270deg, rgba(31, 67, 102, 0) 0%, rgba(31, 67, 102, 1) 100%);
      opacity: 0.25;
      z-index: -99;
    }

    .text {
      // margin-left: 38px;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1.78px;
      text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1.56px;
      line-height: 20.27px;
      color: rgba(172, 201, 230, 1);
      z-index: 99;
    }
  }

  .title-border {
    display: flex;
    margin-bottom: 12px;

    .border1 {
      width: 8px;
      height: 0;
      border: 2px solid rgba(82, 153, 204, 1);
      margin-right: 6px;
    }

    .border2 {
      width: 8px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(41, 77, 102, 1);
      margin-right: 5px;
    }

    .border3 {
      width: 8px;
      height: 0px;
      opacity: 1;
      border: 2px solid rgba(41, 77, 102, 1);
      margin-right: 8px;
    }

    .border4 {
      width: 357.31px;
      height: 0px;
      opacity: 1;
      border: 1px solid rgba(29, 49, 64, 1);
    }
  }

  .item {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 13px;
    &:nth-child(2){
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .item-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(79, 240, 255, 1);
      margin-left: 11px;
    }

    .item-content {
      width: 100%;
      // height: 83px;
      background: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-right-center-right-bg.png') 0 0 no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      // padding-top: 10px;
      padding: 21px 0 7px 0;

      .item-img {
        width: 40px;
        height: 40px;
        margin-left: 11px;
        margin-right: 11px;
      }

      .item-text {
        width: 327px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}</style>
