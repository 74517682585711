<template>
    <div id="centerRight1">
        <div class="title">
            <span class="text">用户发布信息</span>
        </div>
        <div class="title-border">
            <div class="border1"></div>
            <div class="border2"></div>
            <div class="border3"></div>
            <div class="border4"></div>
        </div>
        <div class="content">
            <div class="item" v-for="(item, index) in data" :key="index">
                <div class="item-num">
                    <span class="num" v-change="{ eUnit: '' }">{{ item.num }}</span>
                    <span class="unit">{{ item.unit }}</span>
                </div>
                <div class="item-text">{{ item.text }}</div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            data: [
                {
                    num: 118,
                    unit: '人',
                    text: '白菜'
                },
                {
                    num: 486,
                    unit: '人',
                    text: '苹果'
                },
                {
                    num: 280,
                    unit: '人',
                    text: '樱桃'
                },
                {
                    num: 310,
                    unit: '人',
                    text: '红薯'
                },
                {
                    num: 510,
                    unit: '人',
                    text: '猕猴桃'
                },
                {
                    num: 196,
                    unit: '人',
                    text: '中药材'
                },
                {
                    num: 134,
                    unit: '人',
                    text: '食用菌'
                },
                {
                    num: 470,
                    unit: '人',
                    text: '小麦'
                }
            ]
        }
    }
}
</script>
  
<style lang="scss" scoped>
// $box-height: 410px;
// $box-width: 300px;
#centerRight1 {
    width: 400px;
    margin-top: 10px;

    .title {
        width: 100%;
        height: 29px;
        // background-image: url("../../assets/title.png");
        // background-size: cover;
        display: flex;
        align-items: center;
        padding-left: 16px;

        &::after {
            background: linear-gradient(270deg, rgba(31, 67, 102, 0) 0%, rgba(31, 67, 102, 1) 100%);
            opacity: 0.25;
            z-index: -99;
        }

        .text {
            // margin-left: 38px;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 1.78px;
            text-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.56px;
            line-height: 20.27px;
            color: rgba(172, 201, 230, 1);
            z-index: 99;
        }
    }

    .title-border {
        display: flex;
        margin-bottom: 20px;

        .border1 {
            width: 8px;
            height: 0;
            border: 2px solid rgba(82, 153, 204, 1);
            margin-right: 6px;
        }

        .border2 {
            width: 8px;
            height: 0px;
            opacity: 1;
            border: 2px solid rgba(41, 77, 102, 1);
            margin-right: 5px;
        }

        .border3 {
            width: 8px;
            height: 0px;
            opacity: 1;
            border: 2px solid rgba(41, 77, 102, 1);
            margin-right: 8px;
        }

        .border4 {
            width: 357.31px;
            height: 0px;
            opacity: 1;
            border: 1px solid rgba(29, 49, 64, 1);
        }
    }

    .content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 72px;
            height: 72px;
            background: url('https://nonglian.oss-cn-shanghai.aliyuncs.com/page2-center-right4-bg.png') 0 0 no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-right: 32px;
            margin-bottom: 19px;

            &:nth-child(4n) {
                margin-right: 0px;
            }

            .item-num{
                margin-top: 17px;
            }
            .num {
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 1.88px;
                color: rgba(255, 255, 255, 1);
            }

            .unit {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.25px;
                color: rgba(255, 255, 255, 1);
            }

            .item-text {
                font-size: 12px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
                margin-top: 2px;
            }
        }
    }
}
</style>
  